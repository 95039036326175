/*
!!! WARNING:
    Do not rename this file as it is used in webpack-config-generator.
    (See around webpack.IgnorePlugin).
!!!
*/

import npath from 'path';
import { mockFetch } from '../tests/mocks/fetch/mockFetch';
import { NotFoundErrorResponse, WebspaceUploadLimitReached } from '../tests/mocks/fetch/fetchMockResponses';
import { ApiUrl } from '../dal/ApiUrl';
import { setFetchInstance } from '../src/services/fetch';
import { getDemoStorage } from './storage/DemoStorage';
import { PAGE_DATA_COMPONENT, TEMPLATE_DATA_COMPONENT, ANALYTICS_GOALS_DATA } from '../dal/index';
import { SubscriptionTypes } from '../src/constants/app';
import { USER_PREFERENCES_DOC_ID } from '../dal/constants';
import Url from "../utils/Url";
import { DemoStorageDataNotFoundError } from "./storage/DemoStorageDataNotFoundError";
import { getWbtgenTrialStorage } from "./services/wbtgenTrialStorage";
import { DemoFileUploadLimitReached } from "./services/DemoFileUploadLimitReached";
import { getTrialAuth } from "../../trial/services/trialAuth";
import { TrialUserCustomAttributes } from '../../trial/lambda-functions/src/TrialUserCustomAttributes';
import { TrialUserAttributes } from '../../trial/lambda-functions/src/TrialUserAttributes';
import { TRIAL_STORAGE_PROTOCOL } from "../../trial/services/storage/constants";
import { mapDocumentSvg } from '../../server/shared/utils/mapDocumentSvg';
import urlBuild from '../utils/urlBuild';
import { getAppConfig } from "../src/components/App/epics/appConfig/appConfig";
import { getTrialDomainName } from "../../server/shared/trial/utils/trialUtils";

const appConfig = getAppConfig(),
    partner = appConfig.partnerName;
const apiUrl = new ApiUrl(getTrialDomainName(partner));

const userDataMigrationResponse = {
    clientDataVersionNumber: 1,
    currentDataVersionNumber: 1,
    latestDataVersionNumber: 1
};

export const mockDemoFetch = () => {
    const
        fetch = mockFetch({
            fallbackToNetwork: true,
        }),
        trialStorage = getWbtgenTrialStorage(),
        trialAuth = getTrialAuth();

    fetch.mock(
        apiUrl.preferencesWebEditorUiState(),
        () => {
            return getDemoStorage()
                .getPreferences()
                .catch((e) => {
                    if (e instanceof DemoStorageDataNotFoundError) {
                        return new NotFoundErrorResponse();
                    }
                    throw e;
                });
        },
        {
            name: 'GET preferencesWebEditorUiState()',
            method: 'GET',
        },
    );

    fetch.mock(
        apiUrl.preferencesWebEditorUiState(),
        (_, opts) => {
            const body = JSON.parse(opts.body);

            return getDemoStorage().set({
                id: USER_PREFERENCES_DOC_ID,
                type: USER_PREFERENCES_DOC_ID,
                name: USER_PREFERENCES_DOC_ID,
                ...body,
            })
                .then((data) => {
                    return {
                        ...data,
                    };
                });
        },
        {
            name: 'PUT preferencesWebEditorUiState()',
            method: 'PUT',
        },
    );

    fetch.get(apiUrl.subscriptionStatus(), {
        availableUpgrades: [],
        status: 'ok',
        subscriptionType: SubscriptionTypes.DEMO,
    });
    fetch.get(apiUrl.migrate(), userDataMigrationResponse);
    fetch.get(apiUrl.subscriptionStatusBackupRestore(), {});
    fetch.get(apiUrl.ownerInfo(), () => trialAuth
        .getUserInfo()
        .then((userInfo) => ({
            email: userInfo.username,
            isTrial: true
        })));
    fetch.get(apiUrl.encryptedDomain(), {});
    fetch.get(apiUrl.getAllDocsWithComponentWrappedInTable(), {
        status: "success",
        docs: [],
    });
    fetch.get(apiUrl.publish(), {});

    fetch.get(apiUrl.site(), () => {
        return getDemoStorage()
            .getSite()
            .then(data => {
                return data;
            });
    });
    fetch.get(apiUrl.siteSettings(), () => {
        return getDemoStorage()
            .getSiteSettings()
            .catch((e) => {
                if (e instanceof DemoStorageDataNotFoundError) {
                    return new NotFoundErrorResponse();
                }
                throw e;
            });
    });
    fetch.get(apiUrl.checkIfComponentHasReservedContent(), { reservedContentPresent: false });

    fetch.matchParams(apiUrl.doc(':type', ':id'), (url, opts) => {
        const
            { body } = opts,
            doc = JSON.parse(body);

        return getDemoStorage()
            .set(doc)
            .then(data => {
                return {
                    etag: data.etag,
                    id: doc.id,
                };
            });
    }, { method: 'PUT' });

    fetch.get(

        // @ts-ignore
        url => {
            const
                parsedUrl = Url(url),
                parsedQuery = parsedUrl.query;

            return parsedUrl.pathname === apiUrl.doc()
                && parsedQuery.get('prop') === 'globalId'
                && parsedQuery.get('stubs') === 'true';
        },

        // TODO: response with real data ?
        [],
    );

    fetch.post(apiUrl.batch(), (url, opts) => {
        const
            { body } = opts,
            docs = JSON.parse(body);

        return getDemoStorage()
            .bulk(docs)
            .then((data) => {
                let idToDataMap = {};
                for (let i = 0; i < data.length; i++) {
                    const item = data[i];
                    if (item && item.Attributes) {
                        idToDataMap[item.Attributes.id] = {
                            ...item.Attributes
                        };
                    }
                }
                return Object
                    .keys(docs)
                    .reduce(
                        (acc, k) => Object.assign(acc, {
                            [k]: docs[k].map(doc => ({
                                ok: true,
                                type: doc.type,
                                id: doc.id,
                                etag: (idToDataMap[doc.id] ? idToDataMap[doc.id].rev : null),
                            })),
                        }),
                        {},
                    );
            });
    });

    const fetchRawSvg = (url) => {
        const handleResponse =
            (promise) => promise
                .then(res => {
                    if (res.status >= 200 && res.status < 300) return res;
                    throw new Error(res.statusText);
                })
                .then(res => res.text());
        if (url.indexOf(TRIAL_STORAGE_PROTOCOL) === 0) {
            return handleResponse(
                trialStorage
                    .makeCurrentUserResourceUrl(url)
                    .then(trialUrl => window.fetch(trialUrl))
            );
        } else { // repository svg
            return handleResponse(window.fetch(urlBuild(apiUrl.rawAsset(), { query: { url } })));
        }
    };

    fetch.matchParams(apiUrl.rawAsset(), (wholeUrl) => {
        const { query: { data: { url: urlInQueryStr } } } = Url(wholeUrl);
        return fetchRawSvg(urlInQueryStr);
    }, { method: 'GET' });

    fetch.matchParams(apiUrl.doc(':type', ':id'), (_, opts) => {
        const
            { params: { type, id } } = opts;

        let set;
        if (type === PAGE_DATA_COMPONENT) {
            set = getDemoStorage().getPageSet(id);
        } else if (type === TEMPLATE_DATA_COMPONENT) {
            set = getDemoStorage().getTemplateSet(id);
        }

        if (!set) {
            return {
                status: 404,
                body: { error: 'NotFound' },
            };
        }

        return set.then((data: any) => {
            const mappedData = { ...data };
            const promiseList: any = [];
            if (data.page) { // `page` is undefined when adding a new page
                promiseList.push(mapDocumentSvg(data.page, fetchRawSvg).then(newPage => { mappedData.page = newPage; }));
            }
            promiseList.push(mapDocumentSvg(data.template, fetchRawSvg).then(newTemplate => { mappedData.template = newTemplate; }));
            return Promise.all(promiseList).then(() => mappedData);
        });
    }, { method: 'GET', query: { all: 'true' } });

    fetch.matchParams(apiUrl.templatePages(':templateId'), (_, opts) => {
        return getDemoStorage().getTemplatePages(opts.params.templateId);
    }, { method: 'GET' });

    fetch.get(`glob:${apiUrl.webspace()}*`, url => {
        const urlData = Url(url);

        if (urlData.query.has('metadata')) {
            return trialStorage
                .makeCurrentUserResourceUrl(urlData.pathname, urlData.query.all())
                .then(url => window.fetch(url).then(res => res.json()));
        } else if (urlData.query.isEmpty()) {
            return trialStorage.listCurrentUserResources();
        }

        throw new Error(`Cannot resolve trial webspace url: ${url}`);
    });

    fetch.put(`glob:${apiUrl.webspace()}*`, (url, opts) => {
        const filename = npath.basename(url);
        return trialStorage.uploadCurrentUserFile(filename, opts.file)
            .catch(e => {
                if (e instanceof DemoFileUploadLimitReached) {
                    return new WebspaceUploadLimitReached();
                }
                throw e;
            });
    });

    fetch.get(apiUrl.ownerInfoEmail(), { email: ['email@example.com'] });

    fetch.get(apiUrl.templatesWithPages(), () => getDemoStorage().getTemplatesWithPages());

    fetch.get(`glob:${apiUrl.analyticsDependencies()}*`, () => trialAuth
        .getUserInfo()
        .then((userInfo) => {
            return getDemoStorage().getAnalyticsGoals().then((analyticsDoc) => {
                // @ts-ignore
                let goalsTimestamps = analyticsDoc.goals ? analyticsDoc.goals : {};
                let oldestAvailableTimestampForSiteData = userInfo.attributes[TrialUserCustomAttributes.CREATED_AT];
                return {
                    serverTimestamp: Date.now(),
                    encryptedDomain: `Trial_${userInfo.attributes[TrialUserAttributes.SUB]}`,
                    oldestAvailableTimestampForSiteData,
                    goalsTimestamps
                };
            });
        }));

    fetch.put(`glob:${apiUrl.analyticsGoalAchieved()}*`, url => {
        const urlData = Url(url);
        const queryData = urlData.query.data;
        const goalId = queryData.goalId;
        return getDemoStorage().getAnalyticsGoals().then((analyticsDoc) => {
            // @ts-ignore
            let goals = analyticsDoc.goals ? analyticsDoc.goals : {};
            let updatedAnalyticsDoc = {
                ...analyticsDoc,
                id: ANALYTICS_GOALS_DATA,
                type: ANALYTICS_GOALS_DATA,
                goals: {
                    ...goals,
                    [goalId]: Date.now()
                }
            };
            // @ts-ignore
            return getDemoStorage().set(updatedAnalyticsDoc);
        }).catch(() => {
            throw new Error(
                `Unable to fetch from document from dynamodb with type:${ANALYTICS_GOALS_DATA} and id: ${ANALYTICS_GOALS_DATA}`
            );
        });
    });

    fetch.get(`glob:${apiUrl.getTrialImportAvailability()}*`, () => new NotFoundErrorResponse());

    fetch.get(`glob:${apiUrl.instagramAccessToken()}*`, () => {
        return getDemoStorage()
            .getInstagramAccessToken()
            .then((data) => {
                return {
                    accessToken: data.instagramAccessToken,
                    igUsername: data.instagramAccountName
                };
            })
            .catch((e) => {
                if (e instanceof DemoStorageDataNotFoundError) {
                    return new NotFoundErrorResponse();
                }
                throw e;
            });
    });

    fetch.post(`glob:${apiUrl.disconnectInstagram()}*`, () => {
        return getDemoStorage().deleteInstagramAccessToken();
    });

    fetch.get(`glob:${apiUrl.facebookFeedAccessToken()}*`, () => {
        return getDemoStorage()
            .getFacebookFeedAccessToken()
            .then((data) => {
                const areAllPageAccounts = data.accountConnectionDetails
                    .reduce((acc, accountData) => { return acc && accountData.isPageAccount; }, true);
                let finalResponse: any = [];
                data.accountConnectionDetails.forEach(accountConnectionData => {
                    finalResponse.push({
                        accessToken: accountConnectionData.access_token,
                        fbUsername: accountConnectionData.name,
                        fbAccountId: accountConnectionData.id,
                        isPageAccount: accountConnectionData.isPageAccount,
                        isVisible: !!accountConnectionData.isVisible
                    });
                });
                return {
                    areAllPageAccounts,
                    accountInformation: finalResponse
                };
            })
            .catch((e) => {
                if (e instanceof DemoStorageDataNotFoundError) {
                    return new NotFoundErrorResponse();
                }
                throw e;
            });
    });

    fetch.post(`glob:${apiUrl.disconnectFacebookFeed()}*`, () => {
        return getDemoStorage().deleteFacebookFeedAccessToken();
    });

    fetch.get(`glob:${apiUrl.facebookChatAccessToken()}*`, () => {
        return getDemoStorage()
            .getFacebookChatAccessToken()
            .then((data) => {
                return {
                    accessToken: data.accessToken,
                    fbUsername: data.fbUsername,
                    fbAccountId: data.fbAccountId
                };
            })
            .catch((e) => {
                if (e instanceof DemoStorageDataNotFoundError) {
                    return new NotFoundErrorResponse();
                }
                throw e;
            });
    });

    fetch.post(`glob:${apiUrl.disconnectFacebookChat()}*`, () => {
        return getDemoStorage().deleteFacebookChatAccessToken();
    });

    fetch.get(`glob:${apiUrl.getSocialMediaComponentsUsage()}*`, () => (
        {
            "totalFacebookFeedGalleryComponents": 0,
            "totalInstagramGalleryComponents": 0
        }
    ));

    fetch.get(apiUrl.paidFeaturesDoc(), { premiumFeaturesData: {
        pages: {},
        templates: {}
    } });

    fetch.post(`glob:${apiUrl.facebookFeedPageVisibility()}*`, (url) => {
        const urlData = Url(url),
            fbAccountId = urlData.query.data.fbAccountId,
            isVisible = urlData.query.data.isVisible === 'true';

        return getDemoStorage()
            .getFacebookFeedAccessToken()
            .then((data) => {
                const updatedAccountConnectionDetails: any = [];
                data.accountConnectionDetails.forEach(accountConnectionData => {
                    if (accountConnectionData.id === fbAccountId) {
                        accountConnectionData.isVisible = isVisible; // eslint-disable-line no-param-reassign
                    }
                    updatedAccountConnectionDetails.push(accountConnectionData);
                });
                return updatedAccountConnectionDetails;
            })
            .then(updatedAccountConnectionDetails => {
                return getDemoStorage().updateFacebookFeedAccountConnection(updatedAccountConnectionDetails);
            }).then(() => {
                return {
                    fbAccountId,
                    pageVisibilityMode: isVisible,
                };
            })
            .catch((e) => {
                if (e instanceof DemoStorageDataNotFoundError) {
                    return new NotFoundErrorResponse();
                }
                throw e;
            });
    });

    setFetchInstance(fetch);
};
